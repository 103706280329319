// Variable overrides
$sidebar-dark-bg: white;
$theme-colors: (
  "primary": #fe4e50,
  "indo": blue,
  "eng": red,
  "bgreen": green
);
$font-family-base: Roboto;
$sidebar-dark-nav-link-color: black;

$sidebar-dark-nav-link-icon-color: black;
$sidebar-dark-nav-link-active-bg: #fe4e50;
